/* ecommerceBanner1 */
.illumin8__ecommerceBanner1-Container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: var(--font-family);
  margin-top: 20px;
}

.illumin8__ecommerceBanner1-Container h3 {
  margin: 250px 50px -40px;
  font-size: 30px;
  color: rgb(0, 0, 0);
}

.illumin8__ecommerceBanner1-Container h1 {
  margin: 50px;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  color: rgb(0, 0, 0);
}

.illumin8__ecommerceBanner1-Container button {
  margin: 50px 50px 50px;
  color: rgb(7, 8, 14);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  justify-content: center;
  background-color: #0a0d13;
  color: white;
  font-size: 20px;
  height: 60px;
  width: 200px;
}

.illumin8__ecommerceBanner1-Container button:hover {
  background-color: #4a0909;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.illumin8__ecommerceBanner1-img img {
  max-width: 100%;
}

/* ecommerceFooter-Container */
.illumin8__ecommerceFooter-Container {
  width: 100%;
  max-height: 655px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: var(--font-family);
  margin-top: 80px;
}

.illumin8__ecommerceFooter-Container h3 {
  margin: 250px 50px -40px;
  font-size: 30px;
  color: rgb(255, 255, 255);
}

.illumin8__ecommerceFooter-Container h1 {
  margin: 50px;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  color: rgb(255, 254, 254);
}

.illumin8__ecommerceFooter-Container button {
  margin: 0px 50px 100px;
  color: rgb(7, 8, 14);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  justify-content: center;
  background-color: #0a0d13;
  color: white;
  text-decoration: none;
  font-size: 20px;
  height: 60px;
  width: 200px;
}

.illumin8__ecommerceFooter-Container button:hover {
  background-color: #4a0909;
  cursor: pointer;
  transition: 0.3s ease-in;
}

@media screen and (max-width: 1050px) {
  .illumin8__ecommerceBanner {
    flex-direction: column;
  }

  .illumin8__ecommerceBanner1-Container {
    margin: 0 0 3rem;
  }

  .illumin8__ecommerceFooter-Container {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .illumin8__ecommerceBanner1-Container h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .illumin8__ecommerceBanner1-Container h3 {
    font-size: 28px;
    line-height: 30px;
  }

  .illumin8__ecommerceBanner1-Container button {
    font-size: 16px;
    line-height: 24px;
  }

  .illumin8__ecommerceFooter-Container h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .illumin8__ecommerceFooter-Container h3 {
    font-size: 28px;
    line-height: 30px;
  }
  .illumin8__ecommerceFooter-Container button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .illumin8__ecommerceBanner1-Container h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .illumin8__ecommerceBanner1-Container h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .illumin8__ecommerceBanner1-Container button {
    font-size: 12px;
    line-height: 10px;
    height: 40px;
    width: 150px;
  }

  .illumin8__ecommerceFooter-Container h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .illumin8__ecommerceFooter-Container h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .illumin8__ecommerceFooter-Container button {
    font-size: 12px;
    line-height: 10px;
    height: 40px;
    width: 150px;
  }
}
