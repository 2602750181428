body {
  background: #12111f;
  margin: 0;
  padding: 0;
}
p {
  color: #000000;
  margin-bottom: 0;
}
.illumin8__blog {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.wrapper {
  width: 100%;
  height: 70vh;
  overflow: hidden;
}
.wrapper > .scroller {
  width: auto;
  height: calc(70vh + 20px);
  overflow: auto;
  display: flex;
  scroll-behavior: smooth;
}

.slide {
  width: 60vw;
  height: 60vh;
  margin-top: 5vh;
  margin-left: 10vw;
  margin-right: 10vw;
  position: relative;
  transition: 1s ease;
}
.slide > .blogPhoto {
  width: inherit;
  height: inherit;
  transition: 1s ease;
}
.slide > .blogPhoto:before {
  content: "";
  position: absolute;
  color: #ffffff;
  transform: rotate(-90deg);
  top: 40px;
  left: -60px;
}

.slide > .blogPhoto > img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.slide > .text {
  position: absolute;
  right: -50px;
  bottom: 20%;
  color: white;
  font-size: 80px;
  font-family: arial, sans-serif;
  line-height: 50px;
  transition: 0.8s ease;
}
.slide > .text > h2 {
  font-size: 75px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.slide > .text > span {
  font-size: 30px;
  line-height: 20px;
}

@media screen and (max-width: 700px) {
  .slide {
    width: 70vw;
    height: 45vh;
    margin-top: 5vh;
    margin-left: 6vw;
    margin-right: 10vw;
    position: relative;
    transition: 1s ease;
  }
  .slide > .text > span {
    font-size: 20px;
    line-height: 20px;
  }
  .slide > .text > h2 {
    font-size: 28px;
    line-height: 28px;
  }
}
