.scene {
  position: relative;
  height: 100vh;
  background: #1d1f20;
  text-align: center;
}

.magic {
  z-index: 5;
  position: absolute;
  top: calc(50% - 10rem);
  left: calc(50% - 10rem);
  width: 20rem;
  height: 20rem;
  background: url("../../assets/yellowbg.png") 50% 50% no-repeat fixed;
  background-size: cover;
  border-radius: 50%;
}



