.illumin8__onlineOrder {
  padding-top: 8rem;
}

.illumin8__onlineOrderCover-bgImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: var(--font-family);

  margin: auto;
  padding: 30px;
}

.illumin8__onlineOrderCover-bgImg h1 {
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: rgb(255, 254, 254);
  margin-bottom: 20px;
}

.illumin8__onlineOrderCover-bgImg h2 {
  font-size: 20px;
  color: rgb(255, 255, 255);
}

.illumin8__onlineOrderCover-bgImg h3 {
  font-size: 15px;
  font-weight: lighter;
  color: rgb(255, 255, 255);
}

.illumin8__onlineOrderCover-content {
  background-color: rgb(0, 0, 0);
  padding: 2rem;
  opacity: 0.8;
  text-align: center;
  margin-top: 150px;
}

.illumin8__onlineOrderCover-bgImg button {
  color: rgb(7, 8, 14);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  background-color: #0a0d13;
  color: white;
  text-decoration: none;
  font-size: 15px;
  height: 40px;
  width: 340px;

  /* margin-bottom: 100; */
}

.illumin8__onlineOrderCover-bgImg button:hover {
  background-color: #ff4603;
  cursor: pointer;
  transition: 0.3s ease-in;
}

#OOCButoon1 {
  margin: 100px 10px 10px;
}

@media screen and (max-width: 490px) {
  .illumin8__onlineOrderCover-bgImg h1 {
    font-size: 40px;
    line-height: 28px;
  }

  .illumin8__onlineOrderCover-bgImg h3 {
    font-size: 15px;
    line-height: 12px;
  }

  .illumin8__onlineOrderCover-bgImg button {
    font-size: 15px;
    line-height: 10px;
    height: 40px;
    width: 300px;
  }
}
