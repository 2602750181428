.illumin8__EcommerceNavbar {
  padding-top: 8rem;
  font-family: var(--font-family);
}

/* @media only screen and (max-width: 1300px) {
  .illumin8__ecommerceItems .illumin8__ecommerceItems-clothing {
    grid-template-columns: 1fr;
    width: 18%;
  }

  .illumin8__ecommerceItems .illumin8__ecommerceItems-shoes {
    grid-template-columns: 1fr;
    width: 18%;
  }
  .illumin8__ecommerceItems .illumin8__ecommerceItems-bags {
    grid-template-columns: 1fr;
    width: 18%;
  }
  .illumin8__ecommerceItems .illumin8__ecommerceItems-accessories {
    grid-template-columns: 1fr;
    width: 18%;
  }

  .illumin8__ecommerceItems h4 {
    font-size: 28px;
  }
} */


