figure.illumin8__caseStudy-card {
  font-family: var(--font-family);
  color: #fff;
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 300px;
  width: 100%;
  color: #000000;
  text-align: center;
}
figure.illumin8__caseStudy-card * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
figure.illumin8__caseStudy-card img {
  opacity: 1;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.illumin8__caseStudy-card:after {
  background: #ffffff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  opacity: 0.75;
  -webkit-transform: skew(-45deg) scaleX(0);
  transform: skew(-45deg) scaleX(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
figure.illumin8__caseStudy-card figcaption {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
figure.illumin8__caseStudy-card h2,
figure.illumin8__caseStudy-card p {
  margin: 0;
  width: 100%;
  opacity: 0;
  color: #000000;
}
figure.illumin8__caseStudy-card h2 {
  padding: 0 30px;
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
}
figure.illumin8__caseStudy-card p {
  padding: 0 50px;
  font-size: 0.8em;
  font-weight: 500;
}
figure.illumin8__caseStudy-card button {
  font-family: var(--font-family);
  background-color: black;
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  border: none;
  overflow: unset;
  height: 55px;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 30%;
  right: 30%;
  z-index: 1;
}

figure.illumin8__caseStudy-card button:hover {
  color: black;
  border-radius: 1rem;
  background-color: transparent;
  border: 4px solid black;
}

figure.illumin8__caseStudy-card:hover:after,
figure.illumin8__caseStudy-card.hover:after {
  -webkit-transform: skew(-45deg) scaleX(1);
  transform: skew(-45deg) scaleX(1);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
figure.illumin8__caseStudy-card:hover figcaption h2,
figure.illumin8__caseStudy-card.hover figcaption h2,
figure.illumin8__caseStudy-card:hover figcaption p,
figure.illumin8__caseStudy-card.hover figcaption p {
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
figure.illumin8__caseStudy-card:hover figcaption h2,
figure.illumin8__caseStudy-card.hover figcaption h2 {
  opacity: 1;
}
figure.illumin8__caseStudy-card:hover figcaption p,
figure.illumin8__caseStudy-card.hover figcaption p {
  opacity: 0.7;
}

/* =================================== */

.illumin8__brandingPage__header {
  display: flex;
  flex-direction: row;
}

.illumin8__brandingPage-img img {
  width: 100%;
  height: auto;
}

.illumin8__brandingPage-img img:hover {
  animation: scaleAnimation 2s ease-in-out;
}

.illumin8__brandingPage__header-content {
  /* flex: 1; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  /* text-align: center; */
  display: grid;
}

.illumin8__brandingPage__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 80px;
  line-height: 75px;
  letter-spacing: -0.04em;
  margin-top: 4rem;
  text-align: center;
  justify-content: center;
  color: black;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(1.2); /* Increased size */
  }
  100% {
    transform: scale(1); /* Return to initial size */
  }
}

@media screen and (min-width: 2000px) {
  figure.illumin8__caseStudy-card {
    height: 500px;
  }

  figure.illumin8__caseStudy-card h2 {
    font-size: 35px;
  }
  figure.illumin8__caseStudy-card p {
    font-size: 20px;
  }
  figure.illumin8__caseStudy-card button {
    left: 35%;
    right: 35%;
  }
  .illumin8__brandingPage__header-content h1 {
    font-size: 90px;
    line-height: 110px;
  }

  .illumin8__brandingPage__header-content p {
    font-size: 28px;
    line-height: 35px;
  }
}

@media screen and (max-width: 1350px) {
  figure.illumin8__caseStudy-card button {
    left: 20%;
    right: 20%;
  }
}

@media screen and (max-width: 1050px) {
  figure.illumin8__caseStudy-card button {
    left: 15%;
    right: 15%;
  }
  .illumin8__brandingPage__header {
    flex-direction: column;
  }

  .illumin8__brandingPage__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  figure.illumin8__caseStudy-card button {
    left: 20%;
    right: 20%;
  }
  .illumin8__brandingPage__header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .illumin8__brandingPage__header-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  figure.illumin8__caseStudy-card button {
    left: 20%;
    right: 20%;
  }
  .illumin8__brandingPage__header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .illumin8__brandingPage__header-content p {
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 375px) {
  figure.illumin8__caseStudy-card button {
    left: 20%;
    right: 20%;
  }
  .illumin8__brandingPage__header-content h1 {
    font-size: 30px;
    line-height: 48px;
  }

  .illumin8__brandingPage__header-content p {
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 280px) {
  figure.illumin8__caseStudy-card button {
    left: 15%;
    right: 15%;
  }
}
