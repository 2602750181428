.illumin8__brand {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.illumin8__brand div {
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 180px; /* Default width for smaller screens */
  height: 50px; /* Default height for smaller screens */
}

/* Media query for large screens */
@media (min-width: 2000px) {
  .logo {
    width: 240px;
    height: 66px;
  }
  .illumin8__brand div {
    margin: 2rem 8rem;
  }
}

/* Media query for medium-sized screens */
@media (max-width: 768px) {
  .logo {
    width: 120px;
    height: 33px;
  }
  .illumin8__brand div {
    margin: 1rem;
  }
}
