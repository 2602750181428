@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: rgb(255, 223, 41);

  --gradient-bar: linear-gradient(103.22deg, #fff59d -13.86%, #ffeb3b 99.55%);
  --gradient-service: linear-gradient(
    103.22deg,
    #e0f2f1 -13.86%,
    #b2dfdb 99.55%
  );

  --color-bg: #ffffff;
  --color-footer: #000000;
  --color-yellow: rgb(255, 223, 41);
  --color-text: #000000;
}
