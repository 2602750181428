.div1 {
  flex: 1;
  order: 1; /* Default order, will be shown first on larger screens */
}

.div2 {
  flex: 1;
  order: 2; /* Default order, will be shown second on larger screens */
}

@media screen and (min-width: 2000px) {
  .illumin8__CTABranding {
    margin: 0rem 30rem;
  }
  .illumin8__CTAWeb {
    margin: 0rem 30rem;
  }

  .illumin8__CTASEO {
    margin: 0rem 30rem;
  }

  .illumin8__CTASMM {
    margin: 0rem 30rem;
  }
}

@media screen and (max-width: 1650px) {
  .illumin8__servicesHomePage {
    margin: 0rem 4rem;
  }
}

@media screen and (max-width: 1350px) {
  .illumin8__servicesHomePage {
    margin: 0rem 0rem;
  }
}

@media screen and (max-width: 750px) {
  .div1 {
    order: 2;
  }

  .div2 {
    order: 1;
  }
}

@media screen and (max-width: 650px) {
  .illumin8__wrapper_info {
    margin-top: 8rem;
  }
}
