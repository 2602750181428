.illumin8__bg {
  background: url("./assets/bgGray4.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.illumin8__bgy {
  background: url("./assets/graybg3.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.illumin8__bgGD {
  background: url("./assets/bgGray1.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.illumin8__bgNav {
  background: url("./assets/bgNav.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.illumin8__title {
  font-family: var(--font-family);
  color: black;
  font-weight: 800;
  font-size: 45px;
  line-height: 55px;
  letter-spacing: -0.04em;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.illumin8__wrapper {
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  align-items: center;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.illumin8__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.illumin8__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.illumin8__wrapper_img-reverse {
  justify-content: center;
  align-items: flex-start;
}

.illumin8__wrapper_img img {
  width: 100%;
  border-radius: 1rem;
}

.illumin8__button__Home {
  font-family: var(--font-family);
  background-color: black;
  color: var(--color-yellow);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  border: none;
  overflow: unset;
  height: 55px;
  transition: all 0.3s ease;
  animation: scaleAnimation 2s infinite;
}

.illumin8__button__Home:hover {
  color: black;
  border-radius: 1rem;
  background-color: transparent;
  border: 4px solid black;
}

.illumin8__button__Contained {
  font-family: var(--font-family);
  background-color: black;
  color: var(--color-yellow);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  border: none;
  overflow: unset;
  height: 55px;
  transition: all 0.3s ease;
}

.illumin8__button__Contained:hover {
  color: black;
  border-radius: 1rem;
  background-color: transparent;
  border: 4px solid black;
}

.illumin8__p__cormorant {
  font-family: var(--font-family);
  color: black;
  font-weight: 550;
  letter-spacing: 0.04em;
  line-height: 30px;
  font-size: 20px;
}

.illumin8__p__opensans {
  font-family: var(--font-family);
  color: black;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 26px;
  font-size: 16px;
}

.illumin8__header__p__opensans {
  font-family: var(--font-family);
  color: black;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 26px;
}

.illumin8__headtext__cormorant {
  font-family: var(--font-family);
  color: black;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.04em;
}

.illumin8__headtext__metallic {
  font-family: var(--font-family);
  color: #212121;
  font-size: 55px;
  font-weight: 800;
  line-height: 65px;
  letter-spacing: 0.04em;
  position: relative;
  left: 0;
  transition: left 1s ease-in-out;
}

.illumin8__subHeading {
  font-family: var(--font-family);
  color: var(--color-yellow);
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.04em;
}

.illumin8__headtext__aboutUs {
  font-family: var(--font-family);
  color: var(--color-yellow);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 35px;
  font-size: 25px;
}

.illumin8__services__title {
  position: relative;
  font-family: var(--font-family);
  font-size: 6rem;
  text-transform: uppercase;
  color: #fff;
  z-index: 10;
}

.illumin8__services__subtitle {
  font-family: var(--font-family);
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #fff;
}

.gradient__text {
  font-family: var(--font-family);
  background: linear-gradient(89.97deg, #757575 1.84%, #212121 102.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 1000;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__text-service {
  background: linear-gradient(89.97deg, #009688 1.84%, #004d40 102.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 2rem;
}

.section__margin {
  margin: 4rem 20rem;
}

/* From animista */
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(1.1); /* Increased size */
  }
  100% {
    transform: scale(1); /* Return to initial size */
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@media screen and (min-width: 2000px) {
  .illumin8__services__title {
    font-size: 10rem;
    line-height: 11rem;
  }

  .illumin8__services__subtitle {
    font-size: 3rem;
    line-height: 4rem;
  }

  .illumin8__button__Contained {
    font-size: 25px;
    line-height: 35px;
    height: 60px;
  }

  .illumin8__p__cormorant {
    font-size: 30px;
    line-height: 40px;
  }

  .illumin8__p__opensans {
    font-size: 18px;
    line-height: 35px;
  }

  .illumin8__header__p__opensans {
    font-size: 22px;
    line-height: 32px;
    margin-top: 4rem;
    margin: 2rem 20rem;
  }

  .illumin8__headtext__cormorant {
    font-size: 46px;
    line-height: 56px;
  }

  .illumin8__headtext__metallic {
    font-size: 100px;
    line-height: 60px;
  }
  .illumin8__headtext__aboutUs {
    font-size: 30px;
    line-height: 40px;
  }

  .gradient__text {
    font-size: 80px;
    line-height: 55px;
  }
}

@media screen and (max-width: 1350px) {
  .illumin8__headtext__cormorant {
    font-size: 30px;
    line-height: 40px;
  }
  .illumin8__headtext__aboutUs {
    font-size: 25px;
    line-height: 35px;
  }

  .illumin8__headtext__metallic {
    font-size: 40px;
    line-height: 50px;
  }

  .illumin8__p__cormorant {
    font-weight: 600;
    line-height: 30px;
    font-size: 20px;
  }

  .illumin8__p__opensans {
    font-size: 18px;
    line-height: 28px;
  }

  .gradient__text {
    font-size: 44px;
    line-height: 54px;
  }
}

@media screen and (max-width: 1024px) {
  .illumin8__services__title {
    font-size: 7rem;
    line-height: 8rem;
  }

  .illumin8__services__subtitle {
    font-size: 2rem;
    line-height: 3rem;
  }

  .illumin8__headtext__aboutUs {
    font-size: 30px;
    line-height: 40px;
  }

  .illumin8__headtext__cormorant {
    font-size: 20px;
    line-height: 30px;
  }

  .illumin8__headtext__metallic {
    font-size: 40px;
    line-height: 50px;
  }

  .illumin8__p__cormorant {
    font-weight: 600;
    line-height: 30px;
    font-size: 20px;
  }

  .illumin8__p__opensans {
    font-size: 14px;
    line-height: 24px;
  }

  .gradient__text {
    font-size: 44px;
    line-height: 54px;
  }
}

@media screen and (max-width: 700px) {
  .illumin8__wrapper {
    flex-direction: column;
  }

  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }

  .illumin8__p__cormorant {
    font-size: 18px;
    line-height: 28px;
  }

  .illumin8__header__p__opensans {
    font-size: 18px;
  }

  .illumin8__headtext__aboutUs {
    font-size: 20px;
    line-height: 30px;
  }

  .illumin8__headtext__metallic {
    font-size: 35px;
    line-height: 45px;
  }

  .gradient__text {
    font-size: 38px;
    line-height: 48px;
  }
}

@media screen and (max-width: 550px) {
  .illumin8__services__title {
    font-size: 3rem;
    line-height: 4rem;
  }

  .illumin8__services__subtitle {
    font-size: 1rem;
    line-height: 3rem;
  }
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }

  .illumin8__button__Contained {
    font-size: 16px;
    line-height: 26px;
    height: 50px;
  }

  .illumin8__p__opensans {
    font-size: 14px;
    line-height: 24px;
  }

  .illumin8__p__cormorant {
    font-size: 16px;
    font-weight: 700;
  }

  .illumin8__header__p__opensans {
    font-size: 14px;
    line-height: 22px;
  }

  .illumin8__headtext__cormorant {
    font-size: 20px;
    line-height: 32px;
  }

  .illumin8__headtext__aboutUs {
    font-size: 20px;
    line-height: 25px;
  }

  .illumin8__headtext__metallic {
    font-size: 30px;
    line-height: 40px;
  }

  .gradient__text {
    font-size: 36px;
  }
}

@media screen and (max-width: 375px) {
  .illumin8__button__Home {
    font-size: 14px;
    line-height: 24px;
  }
  .illumin8__button__Contained {
    font-size: 14px;
    line-height: 24px;
    height: 45px;
  }
  .illumin8__headtext__metallic {
    margin-top: 2rem;
    font-size: 24px;
    line-height: 34px;
  }
  .gradient__text {
    font-size: 30px;
  }
}
