.illumin8__appointment {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.illumin8__appointment-form h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
}

.illumin8__appointment-form form {
  font-family: var(--font-family);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 1rem 0.5rem;
}

.illumin8__appointment-form form input {
  font-family: var(--font-family);
  height: 35px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

.illumin8__appointment-form form textarea {
  font-family: var(--font-family);
  margin-top: 15px;
  margin-bottom: 25px;
  height: 70px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

.illumin8__appointment-form input::placeholder,
.illumin8__appointment-form textarea::placeholder {
  font-weight: bold;
  font-family: var(--font-family);
}
.illumin8__appointment-form form input:focus,
.illumin8__appointment-form textarea:focus {
  outline: none;
}

.illumin8__appointment-form form label {
  font-weight: 600;
  margin-top: 15px;
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 390px) {
  .illumin8__appointment-form h1 {
    font-size: 28px;
  }
}
