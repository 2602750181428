body {
  background: rgba(218, 218, 218, 1);
}

a {
  color: #111;
}

.photo {
  position: relative;
  transform: translate(-50%, -50%);
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 10px 15px 25px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: -10px;
  border-radius: 20px;
  justify-content: center;
  justify-items: center;
}

.photo:hover img {
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.photo .glow-wrap {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  margin-top: -10px;
}

.photo .glow {
  display: block;
  position: absolute;
  width: 40%;
  height: 200%;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  filter: blur(5px);
  transform: rotate(45deg) translate(-450%, 0);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.photo:hover .glow {
  transform: rotate(45deg) translate(450%, 0);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.photo:hover img,
.photo:hover .glow-wrap {
  margin-top: 0;
}

.illumin8__services__header-h1 {
  position: relative;
  z-index: 1;
  font-family: var(--font-family);
  margin-bottom: 2rem;
  line-height: 1.2;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* ========================= */

.div1 {
  flex: 1;
  order: 1;
}

.div2 {
  flex: 1;
  order: 2;
}

@media screen and (min-width: 2000px) {
  .illumin8__services {
    margin: 0rem 30rem;
  }
  .illumin8__aboutPage {
    margin: 0rem 20rem;
  }
  .illumin8__services__header-h1 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media screen and (max-width: 1650px) {
  .illumin8__services {
    margin: 0rem 10rem;
  }
}
@media screen and (max-width: 1350px) {
  .illumin8__services {
    margin: 0rem 0rem;
  }
}

@media screen and (max-width: 1024px) {
  #div1 {
    order: 2;
  }
  #div2 {
    order: 1;
  }
  .illumin8__services__header-h1 {
    margin-bottom: 1rem;
    font-size: 22px;
    line-height: 32px;
  }
  .illumin8__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .illumin8__services__header-h1 {
    font-size: 18px;
    line-height: 31px;
  }
}

@media screen and (max-width: 750px) {
  .div1 {
    order: 2;
  }

  .div2 {
    order: 1;
  }
}

@media screen and (max-width: 550px) {
  .illumin8__services__header-h1 {
    font-size: 21px;
    line-height: 31px;
  }
}

@media screen and (max-width: 375px) {
  .illumin8__services__header-h1 {
    font-size: 20px;
    line-height: 30px;
  }
}
