.illumin8__navbar {
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.illumin8__navbar img {
  width: 260px;
  height: 50px;
  z-index: 9999;
}

.illumin8__navbar-img img {
  width: 260px;
  height: 50px;
  margin-top: 5rem;
}

.illumin8__navbar-content p {
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 35px;
  line-height: 45px;
  letter-spacing: 0.1rem;
  color: white;
  padding: 20px;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.illumin8__navbar-content p:hover {
  color: rgb(255, 223, 41);
}
.illumin8__navbar-container button:hover {
  color: rgb(255, 223, 41);
}

@media screen and (min-width: 2000px) {
  .illumin8__navbar-content p {
    font-size: 55px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 1350px) {
  .illumin8__navbar-content p {
    font-size: 35px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 900px) {
  .illumin8__navbar-content p {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 650px) {
  .illumin8__navbar-content p {
    font-size: 20px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 390px) {
  .illumin8__navbar-content p {
    font-size: 18px;
    line-height: 20px;
  }
}
